<template>
  <div class="px-10">
    <div class="text-h4 primary--text mb-5">
      {{ isUpdate ? 'แก้ไขข้อมูลรถ' : 'เพิ่มข้อมูลรถ' }}
    </div>

    <v-card>
      <v-card-text>
        <!-- <v-row>
          <v-col cols="2">ข้อมูลเล่มทะเบียนรถ</v-col>
          <v-col> -->
        <v-row justify="space-around">
          <v-col cols="11" class="text-h6 primary--text">
            ข้อมูลเล่มทะเบียนรถ
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="plate_character"
              v-model="form.plate_character"
              label="หมวดทะเบียน"
              outlined
              dense
              :error-messages="
                $v.form.plate_character.$error ? $error_text : ''
              "
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="plate_number"
              v-model="form.plate_number"
              label="เลขทะเบียนรถ"
              outlined
              dense
              :error-messages="$v.form.plate_number.$error ? $error_text : ''"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              item-value="value"
              item-text="name"
              v-model="form.plate_province"
              :error-messages="$v.form.plate_province.$error ? $error_text : ''"
              label="จังหวัด"
              :items="list.provinces"
              :menu-props="{ maxHeight: '400' }"
              outlined
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-select
              item-value="value"
              item-text="name"
              v-model="form.car_type"
              label="ประเภทรถ"
              :items="list.car_type_dict"
              :menu-props="{ maxHeight: '400' }"
              @change="isCT4"
              outlined
              dense
              hide-details
              :error-messages="$v.form.car_type.$error ? $error_text : ''"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="car_characterize"
              v-model="form.car_characterize"
              label="ลักษณะรถ"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <DatePicker
              label="วันที่จดทะเบียน"
              :value="form.registration_date"
              @onChange="(val) => (form.registration_date = val)"
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-text-field
              name="car_brand"
              v-model="form.car_brand"
              label="ยี่ห้อรถ"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="car_model"
              v-model="form.car_model"
              label="รุ่นรถยนต์"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="car_year"
              v-model="form.car_year"
              label="ปีรถ"
              outlined
              dense
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-text-field
              name="car_color"
              v-model="form.car_color"
              label="สีรถ"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="body_number"
              v-model="form.body_number"
              label="เลขตัวรถ"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="engine_brand"
              v-model="form.engine_brand"
              label="ยี่ห้อเครื่องยนตร์"
              outlined
              dense
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-text-field
              name="engine_number"
              v-model="form.engine_number"
              label="เลขเครื่องยนต์"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-select
              item-value="value"
              item-text="name"
              v-model="form.car_fuel"
              :items="list.car_fuel"
              label="เชื้อเพลิงหลัก"
              :menu-props="{ maxHeight: '400' }"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <div>เชื้อเพลิงเสริม</div>
            <v-radio-group class="mt-0" row dense v-model="form.extra_fuel">
              <v-radio class="mb-2" name="" label="ไม่มี" :value="0"></v-radio>
              <v-radio class="mb-2" name="" label="CNG" :value="1"></v-radio>
              <v-radio class="mb-2" name="" label="LPG" :value="2"></v-radio>
              <v-radio
                class="mb-2"
                name=""
                label="CNG 100%"
                :value="3"
              ></v-radio>
              <v-spacer />
            </v-radio-group>
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-text-field
              name="car_weight"
              v-model="form.car_weight"
              label="น้ำหนักรถ"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="engine_size"
              v-model="form.engine_size"
              label="ซี ซี เครื่อง"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="gas_tank_number"
              v-model="form.gas_tank_number"
              label="เลขถังแก๊ส"
              outlined
              dense
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <v-col cols="2"></v-col>

          <!-- <v-col cols="3">
            <div class="d-flex align-center-x">
              <div style="width: 70%">
                <uploadImage
                  directory="car_registration_book"
                  :limit="2"
                  :src="form.car_registration_url"
                  @onChangeUrl="form.car_registration_url = $event"
                >
                  <v-text-field
                    label="รูปหน้าทะเบียนรถ"
                    outlined
                    dense
                    :value="form.car_registration_url"
                    hint="คลิกที่นี่ เพื่ออัพโหลดรูป"
                    persistent-hint
                  />
                </uploadImage>
              </div>
              <div style="width: 30%" class="text-right">
                <v-btn
                  color="success"
                  icon
                  @click="dialogImage = true"
                  :disabled="!form.car_registration_url"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  icon
                  @click="deleteImage(form.car_registration_url)"
                  :disabled="!form.car_registration_url"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>

          <v-dialog v-model="dialogImage" max-width="500">
            <v-card>
              <v-card-title primary-title></v-card-title>
              <v-card-text class="text-center">
                <img :src="form.car_registration_url" width="80%" />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey" text @click="dialogImage = false"
                  >ปิด</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog> -->

          <v-col cols="3">
            <div>ชนิดเอกสาร</div>
            <v-radio-group class="mt-0" row v-model="form.is_original_document">
              <v-radio name="" label="สำเนา" :value="false"></v-radio>
              <!-- <v-spacer/> -->
              <v-radio name="" label="เล่มจริง" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="3">
            <DatePicker
              label="วันที่ภาษีขาด"
              :value="form.taxout_date"
              @onChange="(val) => (form.taxout_date = val)"
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <v-col cols="3">
            <v-text-field
              name="register_book_address"
              label="ที่อยู่(จัดส่งเอกสาร)"
              outlined
              dense
              autocomplete="new-password"
              @click="
                (dialogAddressData = form.register_book_address),
                  (dialogAddress = true),
                  (dialogAddressKey = 'register_book_address')
              "
              :value="`${form.register_book_address.address} ${form.register_book_address.sub_district} ${form.register_book_address.district} ${form.register_book_address.province} ${form.register_book_address.postal_code}`"
            />
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="เป็นนิติบุคคล"
              class="mt-0"
              dense
              v-model="form.is_corporation_car"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="รับ SMS แจ้งเตือน"
              class="mt-0"
              dense
              v-model="form.renewal_reminder"
            ></v-checkbox
          ></v-col>
        </v-row>

        <v-divider class="mb-10 mx-15" />

        <v-row justify="space-around">
          <v-col cols="11" class="text-h6 primary--text">
            ข้อมูลเจ้าของรถ
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="customer"
              :items="list.customers"
              :search-input.sync="form.ownership.customer_name"
              return-object
              item-text="name"
              item-value="_id"
              outlined
              dense
              solo
              filled
              @change="changeCustomer"
              label="ชื่อเจ้าของรถ"
              autocomplete="off"
              auto-select-first
              :error-messages="
                $v.form.ownership.customer_name.$error ? $error_text : ''
              "
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="ownership.phone_number"
              v-model="form.ownership.phone_number"
              label="เบอร์โทร"
              outlined
              dense
              disabled
              :type="showPhoneNumber ? 'text': 'password'"
              autocomplete="new-password"
              :error-messages="
                $v.form.ownership.phone_number.$error ? $error_text : ''
              "
            />
          </v-col>
          <v-col cols="3">
            <v-btn
              target="_blank"
              :disabled="!customer"
              color="primary"
              outlined
              :to="{
                name: 'customer-customer-update',
                params: { id: form.ownership.customer_id },
              }"
              >ดูข้อมูลลูกค้า</v-btn
            >
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-divider class="mb-10 mx-15" />

        <v-row justify="space-around">
          <v-col cols="11" class="text-h6 primary--text"> บริษัท พรบ. </v-col>
          <v-col cols="3">
            <v-text-field
              name="third_insurance_company"
              v-model="form.third_insurance_company"
              label="บริษัท พ.ร.บ."
              outlined
              dense
              disabled
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="third_insurance_type"
              v-model="form.third_insurance_type"
              label="ประเภท พ.ร.บ."
              outlined
              dense
              disabled
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="third_insurance_number"
              v-model="form.third_insurance_number"
              label="เลขกรมธรรม์ พรบ."
              outlined
              dense
              @blur="nandleThirdInsuranceNumber()"
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-text-field
              name="third_insurance_modifier_name"
              v-model="form.third_insurance_modifier_name"
              label="ชื่อผู้บันทึกเลขกรมธรรม์"
              outlined
              dense
              disabled
            />
            <DatePicker
              label="วันที่บันทึก"
              :value="form.third_insurance_modifier_date"
              @onChange="(val) => (form.third_insurance_modifier_date = val)"
            />
          </v-col>
          <v-col cols="3">
            <DatePicker
              label="วันที่เริ่มคุ้มครอง"
              :value="form.third_insurance_start_date"
              @onChange="(val) => (form.third_insurance_start_date = val)"
            />
          </v-col>
          <v-col cols="3">
            <DatePicker
              label="สิ้นสุด"
              :value="form.third_insurance_end_date"
              @onChange="(val) => (form.third_insurance_end_date = val)"
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>
        
        <v-divider class="mb-10 mx-15" />

        <v-row justify="space-around">
          <v-col cols="11" class="text-h6 primary--text">
            บริษัท ประกันภัย
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="insurance_company"
              v-model="form.insurance_company"
              label="บริษัทประกัน"
              outlined
              dense
              disabled
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="insurance_type"
              v-model="form.insurance_type"
              label="ประเภท ประกันภัย"
              outlined
              dense
              disabled
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              name="insurance_number"
              v-model="form.insurance_number"
              label="เลขกรมธรรม์ประกันภัย"
              outlined
              dense
              @blur="nandleInsuranceNumber()"
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-text-field
              name="insurance_modifier_name"
              v-model="form.insurance_modifier_name"
              label="ชื่อผู้บันทึกเลขกรมธรรม์"
              outlined
              dense
              disabled
            />
            <DatePicker
              label="วันที่บันทึก"
              :value="form.insurance_modifier_date"
              @onChange="(val) => (form.insurance_modifier_date = val)"
            />
          </v-col>
          <v-col cols="3">
            <DatePicker
              label="วันที่เริ่มคุ้มครอง"
              :value="form.insurance_start_date"
              @onChange="(val) => (form.insurance_start_date = val)"
            />
            
          </v-col>
          <v-col cols="3">
            <DatePicker
              label="สิ้นสุด"
              :value="form.insurance_end_date"
              @onChange="(val) => (form.insurance_end_date = val)"
            />
          </v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-divider class="mb-10 mx-15" />

        <v-row justify="space-around">
          <v-col cols="11" class="text-h6 primary--text">
            ข้อมูลตรวจสภาพรถ
          </v-col>
          <v-col cols="3">
            <div>ผลตรวจสภาพรถ</div>
            <v-radio-group
              class="mt-0"
              row
              dense
              v-model="form.vehicle_examination_result"
            >
              <v-radio name="" label="ผ่าน" :value="true"></v-radio>
              <v-radio name="" label="ไม่ผ่าน" :value="false"></v-radio>
              <v-spacer />
            </v-radio-group>
          </v-col>
          <v-col cols="3"></v-col>
          <v-col cols="3"></v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <v-row justify="space-around">
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="3">
            <v-text-field
              name="vehicle_examination_number"
              v-model="form.vehicle_examination_number"
              label="เลขที่ใบตรวจสภาพรถ"
              outlined
              dense
            />
            <DatePicker
              label="วันที่ตรวจสภาพรถ"
              :value="form.vehicle_inspect_date"
              @onChange="(val) => (form.vehicle_inspect_date = val)"
            />
            <TimePicker
              label="เวลาที่ตรวจสภาพรถ"
              :value="form.vehicle_inspect_time"
              @onChange="(val) => (form.vehicle_inspect_time = val)"
            />
          </v-col>
          <v-col cols="3">
            <v-textarea
              name="note"
              v-model="form.note"
              label="หมายเหตุ"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3"></v-col>
          <!-- <v-col cols="1"></v-col> -->
        </v-row>

        <!-- </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ปิด</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >บันทึกข้อมูล</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddressDetail from '@/components/Address/AddressDetail.vue';
import uploadImage from '@/components/uploadImage.vue';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import S3 from 'aws-s3';
import {
  required,
  maxLength,
  minLength,
  numeric,
  minValue,
} from 'vuelidate/lib/validators';
import * as moment from 'moment';

export default {
  components: {
    AddressDetail,
    uploadImage,
    DatePicker,
    TimePicker,
  },
  validations: {
    form: {
      plate_character: { required },
      plate_number: { required, numeric, minValue: minValue(0) },
      plate_province: { required },
      car_type: { required },
      ownership: {
        customer_name: {},
        phone_number: {
          numeric,
          minLength: minLength(9),
          maxLength: maxLength(10),
        },
      },
    },
  },
  data: () => ({
    // aws s3 file upload config
    file: null,
    uploading: false,
    aws_access_key_id: 'AKIA2ETG3H3HEVIJOFU3',
    aws_secret_access_key: '---',
    aws_default_region: 'ap-southeast-1',
    aws_bucket: 'permtang',
    dirName: 'car_registration_book',
    aws_url: 'https://permtang.s3-ap-southeast-1.amazonaws.com',

    dialogImage: false,
    modal: {
      taxout_date: false,
      registration_date: false,
      third_insurance_start_date: false,
      third_insurance_end_date: false,
      third_insurance_modifier_date: false,
      vehicle_inspect_date: false,
      vehicle_inspect_time: false,
    },
    loading: false,
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAddressData: {
      address: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: '',
    },
    customer: {
      '_id': '',
      'name': '',
      'phone_number': '',
      'location': {
        'address': '',
        'district': '',
        'postal_code': '',
        'province': '',
        'sub_district': '',
      },
      'delivery_address': {
        'address': '',
        'district': '',
        'postal_code': '',
        'province': '',
        'sub_district': '',
      },
      'customer_note':'',
    },
    list: {
      provinces: [],
      customers: [],
      car_fuel: [
        { value: 0, name: 'ไม่ระบุ' },
        { value: 1, name: 'ดีเซล' },
        { value: 2, name: 'เบนซิน' },
        { value: 3, name: 'ไฟฟ้า 100%' },
        { value: 4, name: 'อื่นๆ' },
      ],
      extra_fuel: [
        { value: 0, name: ' ' },
        { value: 1, name: 'CNG' },
        { value: 2, name: 'LPG' },
        { value: 3, name: 'CNG 100%' },
      ],
      car_type_dict: [
        { value: 'CT1', name: '(รย.1) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT2', name: '(รย.2) รถยนต์นั่งส่วนบุคคลเกิน 7 คน' },
        { value: 'CT3', name: '(รย.3) รถยนต์บรรทุกส่วนบุคคล' },
        { value: 'CT4', name: '(รย.12) รถจักรยานยนต์ส่วนบุคคล' },
        { value: 'CT13', name: '(รย.17) รถจักรยานยนต์สาธารณะ' },
        { value: 'CT5', name: '(รย.13) รถแทรกเตอร์ที่ใช้ในการเกษตร' },
        { value: 'CT14', name: '(รย.15) รถใช้งานเกษตรกรรม' },
        {
          value: 'CT6',
          name: '(ขส.10) รถโดยสารประจำทาง (เลขทะเบียนข้างหน้าเป็น 10-19)',
        },
        {
          value: 'CT7',
          name: '(ขส.30) รถโดยสารไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 30-39)',
        },
        {
          value: 'CT8',
          name: '(ขส.40) รถโดยสารส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 40-49)',
        },
        {
          value: 'CT9',
          name: '(ขส.70) รถบรรทุกไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 70-79)',
        },
        {
          value: 'CT10',
          name: '(ขส.80) รถบรรทุกส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 50-59, 80-99)',
        },
        { value: 'CT11', name: '(รย.1EV) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT12', name: 'อื่นๆ' },
      ],
    },
    showPhoneNumber: false,
    form: {
      branch_id:'',
      car_id: 0,
      plate_character: '',
      plate_number: '',
      plate_province: '',
      car_type: '',
      car_characterize: '',
      registration_date: '',
      car_brand: '',
      car_model: '',
      car_year: '',
      car_color: '',
      body_number: '',
      engine_brand: '',
      engine_number: '',
      car_fuel: 0,
      extra_fuel: 0,
      car_weight: '',
      engine_size: '',
      gas_tank_number: '',
      car_registration_url: '',
      is_original_document: false,
      taxout_date: '',
      register_book_address: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: '',
      },
      is_corporation_car: false,
      ownership: {
        customer_id: 0,
        customer_name: '',
        phone_number: '',
        location: {
          address: '',
          district: '',
          sub_district: '',
          province: '',
          postal_code: '',
        },
        delivery_address: {
          address: '',
          district: '',
          sub_district: '',
          province: '',
          postal_code: '',
        },
        customer_note: '',
      },
      third_insurance_company: '',
      third_insurance_type: '',
      third_insurance_modifier_name: '',
      third_insurance_number: '',
      third_insurance_start_date: '',
      third_insurance_end_date: '',
      third_insurance_modifier_date: '',
      insurance_company: '',
      insurance_type: '',
      insurance_modifier_name: '',
      insurance_number: '',
      insurance_start_date: '',
      insurance_end_date: '',
      insurance_modifier_date: '',
      vehicle_examination_result: false,
      vehicle_examination_number: '',
      vehicle_inspect_date: '',
      vehicle_inspect_time: '',
      renewal_reminder: true,
      note: '',
    },
  }),

  async created() {
    this.form.branch_id = await this.$store.state.selected_branch._id;
    await this.getProvinces();
    await this.getShowPhoneNumber();
    await this.getCustomers();
    if (this.isUpdate) this.getData();
  },

  methods: {
    async getProvinces() {      
      this.list.provinces = this.$store.state.list.provinces
    },
    onSubmitAddress(val) {
      this.form[this.dialogAddressKey] = val;
      this.dialogAddress = false;
    },
    async getData() {
      let body = {
        token: this.$jwt.sign(
          { car_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/car/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
          if (this.form.ownership.customer_id) {
            this.customer = {
              _id: this.form.ownership.customer_id,
              name: this.form.ownership.customer_name,
              phone_number: this.form.ownership.phone_number,
              location: this.form.ownership.location,
              delivery_address: this.form.ownership.delivery_address,
              note : this.form.ownership.customer_note,
            };
            this.list.customers = [].concat(this.customer)
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    close() {
      this.$router.push({ name: 'car' });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            delete this.form.company_group_id;
            let subPath = 'add_car';
            if (this.isUpdate) {
              subPath = 'edit_car';
              this.form = { car_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/car/${subPath}`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                if (!this.isUpdate) this.$router.push({ name: 'car' });
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log('save()');
          }
          this.loading = false;
          console.log(this.form);
        }
      );
    },
    onChangeDate(val, kayName) {
      this.form[kayName] = `${moment(val).format('YYYY-MM-DD')}`; //T00:00:00.000000
    },
    onChangeTime(val, kayName) {
      this.form[kayName] = `${val}:00`;
    },
    nandleThirdInsuranceNumber() {
      if (!this.form.third_insurance_modifier_name)
        this.form.third_insurance_modifier_name =
          this.$store.state.profile.name;
      if (!this.form.third_insurance_modifier_date)
        this.form.third_insurance_modifier_date = moment().format('YYYY-MM-DD');
    },
    nandleInsuranceNumber() {
      if (!this.form.insurance_modifier_name)
        this.form.insurance_modifier_name = this.$store.state.profile.name;
      if (!this.form.insurance_modifier_date)
        this.form.insurance_modifier_date = moment().format('YYYY-MM-DD');
    },
    async searchCustomer(val) {
      console.log("searchCustomer");
      if (String(val).length == 0) {
        this.form.ownership = {
          customer_id: 0,
          customer_name: '',
          phone_number: '',
        };
      }
      if ((this.list.customers.length == 0 && String(val).length >= 0) == false) {
        this.getCustomers(val);
      }
    },
    async changeCustomer(customer) {
      this.form.ownership.customer_id = customer._id;
      // this.form.ownership.customer_name = customer.customer_name;
      this.form.ownership.phone_number = customer.phone_number;
      this.form.ownership.location = customer.location;
      this.form.ownership.delivery_address = customer.delivery_address;
      this.form.ownership.customer_note = customer.note;
      console.log("Customer", this.form.ownership);
    },
    async getCustomers(text) {
      console.log("getCustomers");
      if(text) {
        let filter = {
          branch_id: this.$store.state.selected_branch._id,
          start_with: text
        }
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/customer/get_customer_list`, body)
          .then((res) => {
            this.list.customers = res.result;
          })
          .catch((err) => {
            console.log('err', err);
            this.$alertServerError({ title: err.error_message });
          });
      }
    },
    formatDate(date) {
      if (!date) return '';
      let year = parseInt(moment(this.form.registration_date).format('YYYY'));
      let m_d = moment(this.form.registration_date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    handleCustomers() {
      setTimeout(() => {
        let text = this.form.ownership.customer_name;
        this.searchCustomer(text);
      }, 250);
    },
    deleteImage(file_path) {
      this.$alertConfirm({ title: `ต้องการลบรูป ?` }).then(async (result) => {
        if (result.isConfirmed) {
          const config = {
            bucketName: this.aws_bucket,
            dirName: this.dirName,
            region: this.aws_default_region,
            accessKeyId: this.aws_access_key_id,
            secretAccessKey: this.aws_secret_access_key,
            s3Url: this.aws_url,
          };
          let filename = file_path.split('/');
          filename = filename[filename.length - 1];
          const S3Client = new S3(config);
          S3Client.deleteFile(filename)
            .then((response) => {
              this.form.car_registration_url = '';
              const file = document.querySelector('.file');
              console.log(response);
            })
            .catch((err) => console.error(err));
        }
      });
    },
    async isCT4(detail) {
      if(detail == "CT4"){
        this.form.car_characterize = "รถจักรยานยนต์"
      } else {
        this.form.car_characterize = ""
      }
    },
    async getShowPhoneNumber() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
        };

        this.show_phone_number = this.$store.state.group_permissions.show_phone_number;
        if (this.show_phone_number == 0) {
          this.showPhoneNumber = false;
        } else {
          this.showPhoneNumber = true;
        };
      } catch (error) {
        this.showPhoneNumber = true;
        console.error('Error in getShowPhoneNumber:', error);
      };
    }, 
  },
  watch: {
    'form.ownership.customer_name'(newValue) {
      this.getCustomers(newValue || '');
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
